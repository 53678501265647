import React from "react";
import { graphql } from "gatsby";
import { Layout } from "~/templates/Layout";
import { ReportCard } from "~/molecules/ReportCard";
import { PageHeading } from "~/atoms/PageHeading";
import { Container } from "~/atoms/Container";
import { Pager } from "~/molecules/Pager";
import { PATH } from "~/constant/path";
import { colors } from "~/utils/colors";
import { convertYearsToTimes } from "~/utils/converter";

interface Props {
  data: {
    allContentfulReport: GatsbyTypes.ContentfulReportConnection;
  };
  pageContext: {
    limit: number;
    skip: number;
    totalPages: number;
    currentPage: number;
    pageTitle: {
      titleKey: string;
      subTitle: string[];
    };
    times: number;
    year: string;
    breadCrumbPath: string[];
    parentPath: string;
  };
}

const IndexAward: React.FC<Props> = ({ data, pageContext }) => {
  const { times, year, currentPage, totalPages } = pageContext;
  const { allContentfulReport } = data;
  const eventAward = PATH[`EVENTS_AWARD_${year}`];
  const breadCrumbItems = [PATH.TOP, PATH.EVENTS, PATH.EVENTS_AWARD, eventAward];

  return (
    <>
      <style jsx>{`
        .pageWrapper {
          background: ${colors.bgGrayGradient};
          padding-bottom: 50px;
        }

        .awardList {
          display: flex;
          flex-wrap: wrap;
          position: relative;
          width: 100%;
        }
      `}</style>
      <Layout
        title={PATH[`EVENTS_AWARD_${year}`].name}
        ogpDescription={`Referral Recruiting AWARD 第${times}回を受賞された企業の皆様にインタビューを行いました`}
        breadCrumbItems={breadCrumbItems}
      >
        <div className="pageWrapper">
          <PageHeading title={eventAward.name}>
            {`Referral Recruiting AWARD 第${times}回を受賞された`}
            <br className="pcVisible" />
            {`を受賞された企業の皆様にインタビューを行いました`}
          </PageHeading>
          <Container>
            <ul className="awardList">
              {allContentfulReport.edges.map((edge) => {
                const { node } = edge;

                return (
                  <ReportCard
                    key={`IndexAward_${node.contentful_id}`}
                    article={node}
                    href={`${PATH.EVENTS.to}/${node.eventCategory?.slug}/interview${node.awardYear}/${node.contentful_id}`}
                  />
                );
              })}
            </ul>
          </Container>
          <Pager currentPage={currentPage} totalPages={totalPages} basePath={eventAward.to} />
        </div>
      </Layout>
    </>
  );
};

export default IndexAward;

export const indexAwardQuery = graphql`
  query IndexAward($year: Int!) {
    allContentfulReport(
      filter: { eventCategory: { slug: { eq: "award" } }, awardYear: { eq: $year } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          date
          awardYear
          companyName
          ogpImage {
            fluid(maxWidth: 660) {
              src
              aspectRatio
            }
          }
          title
          contentful_id
          eventCategory {
            slug
          }
        }
      }
      totalCount
    }
  }
`;
